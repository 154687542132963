
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93AVdyfmCxNFMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46category_id_93DjvUdIMEJGMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/c/[...category_id].vue?macro=true";
import { default as _91cart_id_93cVeBejU8vRMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/cart/[cart_id].vue?macro=true";
import { default as contacts2n98zi5hyMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/contact.vue?macro=true";
import { default as faqjxbz5gwZY3Meta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/faq.vue?macro=true";
import { default as homeWBGrAgbuxnMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/home.vue?macro=true";
import { default as imprintjHJlNhYgVKMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/imprint.vue?macro=true";
import { default as indexKoGKsYK8yAMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/index.vue?macro=true";
import { default as login_45postr3XL0NLML4Meta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/login-post.vue?macro=true";
import { default as additional_45header_45fieldsgzVkhW4uc2Meta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/additional-header-fields.vue?macro=true";
import { default as addressesKeCuaCUnlFMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/addresses.vue?macro=true";
import { default as branch_45clustersdkspp52b6HMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/branch-clusters.vue?macro=true";
import { default as carts3Ov2oXL5ioMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/carts.vue?macro=true";
import { default as condition_45sheetseb39bWpkXMMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/condition-sheets.vue?macro=true";
import { default as evaluationOv00USBMBFMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/evaluation.vue?macro=true";
import { default as order_45details_45smojl3pxhVB6DMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/order-details-smo.vue?macro=true";
import { default as order_45detailssYNiSspaVNMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/order-details.vue?macro=true";
import { default as order_45history_45smo62OzXMu6WRMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/order-history-smo.vue?macro=true";
import { default as order_45historyAbjdPBmJA2Meta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/order-history.vue?macro=true";
import { default as preferencesKYQt3D3kHqMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/preferences.vue?macro=true";
import { default as my_45accountJGrHmsmNbXMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account.vue?macro=true";
import { default as _91id_93x4d127qJGQMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/p/[id].vue?macro=true";
import { default as privacy_45policyuN47bv5sSeMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/privacy-policy.vue?macro=true";
import { default as _91_46_46_46slug_93TjrTat4SNKMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/product-notes/[...slug].vue?macro=true";
import { default as smss002rSn9XlMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/sms.vue?macro=true";
import { default as _91_46_46_46category_id_939ehLhyj2hAMeta } from "/codebuild/output/src2269847482/src/intersport-order-frontend/pages/workbook/[...category_id].vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93AVdyfmCxNFMeta || {},
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/[...slug].vue")
  },
  {
    name: "c-category_id",
    path: "/c/:category_id(.*)*",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/c/[...category_id].vue")
  },
  {
    name: "cart-cart_id",
    path: "/cart/:cart_id()",
    meta: _91cart_id_93cVeBejU8vRMeta || {},
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/cart/[cart_id].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/contact.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/faq.vue")
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/home.vue")
  },
  {
    name: "imprint",
    path: "/imprint",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/imprint.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/index.vue")
  },
  {
    name: "login-post",
    path: "/login-post",
    meta: login_45postr3XL0NLML4Meta || {},
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/login-post.vue")
  },
  {
    name: "my-account",
    path: "/my-account",
    meta: my_45accountJGrHmsmNbXMeta || {},
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account.vue"),
    children: [
  {
    name: "my-account-additional-header-fields",
    path: "additional-header-fields",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/additional-header-fields.vue")
  },
  {
    name: "my-account-addresses",
    path: "addresses",
    meta: addressesKeCuaCUnlFMeta || {},
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/addresses.vue")
  },
  {
    name: "my-account-branch-clusters",
    path: "branch-clusters",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/branch-clusters.vue")
  },
  {
    name: "my-account-carts",
    path: "carts",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/carts.vue")
  },
  {
    name: "my-account-condition-sheets",
    path: "condition-sheets",
    meta: condition_45sheetseb39bWpkXMMeta || {},
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/condition-sheets.vue")
  },
  {
    name: "my-account-evaluation",
    path: "evaluation",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/evaluation.vue")
  },
  {
    name: "my-account-order-details-smo",
    path: "order-details-smo",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/order-details-smo.vue")
  },
  {
    name: "my-account-order-details",
    path: "order-details",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/order-details.vue")
  },
  {
    name: "my-account-order-history-smo",
    path: "order-history-smo",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/order-history-smo.vue")
  },
  {
    name: "my-account-order-history",
    path: "order-history",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/order-history.vue")
  },
  {
    name: "my-account-preferences",
    path: "preferences",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/my-account/preferences.vue")
  }
]
  },
  {
    name: "p-id",
    path: "/p/:id()",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/p/[id].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/privacy-policy.vue")
  },
  {
    name: "product-notes-slug",
    path: "/product-notes/:slug(.*)*",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/product-notes/[...slug].vue")
  },
  {
    name: "sms",
    path: "/sms",
    meta: smss002rSn9XlMeta || {},
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/sms.vue")
  },
  {
    name: "workbook-category_id",
    path: "/workbook/:category_id(.*)*",
    component: () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/pages/workbook/[...category_id].vue")
  }
]