import validate from "/codebuild/output/src2269847482/src/intersport-order-frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/codebuild/output/src2269847482/src/intersport-order-frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "addresses-router": () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/middleware/addresses-router.ts"),
  "condition-sheets-router": () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/middleware/condition-sheets-router.ts"),
  "sms-router": () => import("/codebuild/output/src2269847482/src/intersport-order-frontend/middleware/sms-router.ts")
}